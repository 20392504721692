import React from 'react'
import ProductAvailabilityContext from "./productAvailabilityContext";
import axios from 'axios';
import {fileAvailableUrl, hostname} from '../../components/Data/api'

class ProductAvailabilityState extends React.Component {

    constructor(props) {
        super(props)

        const updateProductAvailability = () => {
            const url = hostname + fileAvailableUrl + "available/file"

            axios.get(url)
                .then(response => {
                    this.setState({productAvailability:response.data})
                    this.setState({loading:false})

                })
                .catch(err => {
                    console.log(err)
                    this.setState({loading:false})
                })
        }

        const getProductAvailability = (product) => {
            let availability = this.state.productAvailability.filter(key => {return key.product === product})
            return availability[0] !== undefined ?
                [availability[0]["firstDate"], availability[0]["lastDate"]]
                :
                ["", ""]
        }

        this.state = {
            productAvailability: [],
            loading: true,
            updateProductAvailability: updateProductAvailability,
            getProductAvailability: getProductAvailability
        }
    }

    componentDidMount() {
        this.state.updateProductAvailability();
    }

    render() {
        return <ProductAvailabilityContext.Provider
            value = {{
                productAvailability:this.state.productAvailability,
                loading:this.state.loading,
                updateProductAvailability:this.state.updateProductAvailability,
                getProductAvailability:this.state.getProductAvailability
            }}>
            {this.props.children}
        </ProductAvailabilityContext.Provider>
    }
}

export default ProductAvailabilityState