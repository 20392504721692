export const surfaceDataPrefixes = ["ANC","ATL","BDL","BOS","BWI","CLE","CLT","CVG","DCA","DEN","DFW","DTW","EWR",
    "FLL","HNL","HOU","IAD","IAH","JFK","LAS","LAX","LGA","MCI","MCO","MDW","MEM","MIA","MKE","MSP","MSY",
    "ORD","PDX","PHL","PHX","PIT","PVD","SAN","SDF","SEA","SFO","SLC","SNA","STL"]

export const surfaceDataFacilities = surfaceDataPrefixes.map(name => {return (name+"+ASDEX")})

// export const traconFacilities = ["A80","A90","C90","CLT","CVG","D01","D10","D21","F11","I90","L30","M03","M98","MIA",
//     "MKE","N90","NCT","P50","P80","PCT","PHL","S46","S56","SBN","SCT","T75"]

export const centerFacilities = ["ZAB","ZAU","ZBW","ZDC","ZDV","ZFW","ZHU","ZID","ZJX","ZKC","ZLA","ZLC",
    "ZMA","ZME","ZMP","ZNY","ZOA","ZOB","ZSE","ZTL"]

export const taisTraconFacilities = ["A11","A80","A90","ABQ","BUF","C90","CLT","CMH","D01","D10","D21",
    "F11","I90","IND","JAX","M03","M98","MCI","MIA","MKE","N90","NCT","P50","P80","PCT","PHL","PIT","PVD",
    "R90","RDU","S46","S56","SCT","SDF","T75","TPA","Y90"]

export const traconFacilities = taisTraconFacilities

export const swimFlightDataFacilities = ["APDS","ASDEX","ASDI","SFDPS","TAIS","TFM-R10","TFM-R13","BEST FLIGHT PLAN"]

export const ctasFacilities = ["ZAB-P50","ZAU-C90","ZAU-MKE","ZAU-SBN","ZBW-A90","ZBW-N90","ZDC-PCT",
    "ZDV-D01","ZFW-D10","ZHU-I90","ZID-CVG","ZJX-MCO","ZKC-T75","ZLA-L30","ZLA-SCT","ZLC-S56","ZMA-MIA",
    "ZME-MEM","ZMP-M98","ZNY-N90","ZNY-PHL","ZOA-NCT","ZOB-D21","ZSE-P80","ZSE-S46","ZTL-A80","ZTL-CLT"]

export const field10RerouteFacilities = centerFacilities

export const evTypes = ["Go Around","Initialization","Hand Off","Landing","Crossing","Mode of Flight",
    "Passing","Stop Handoff","Stop","User Defined","Top of Climb","Top of Descent","Take Off","Transition",
    "Looping","Reroute"]

export const evTypeValues = ["EV_GOA","EV_INIT","EV_HOFF","EV_LND","EV_XING","EV_MOF","EV_PASS","EV_STOH",
    "EV_STOP","EV_USER","EV_TOC","EV_TOD","EV_TOF","EV_TRNS","EV_LOOP","EV_RRT"]

export const airportCodeValues = ["KATL","KBNA","KBOS","KBWI","KCLT","KDAL","KDCA","KDEN","KDFW","KDTW","KEWR",
    "KFLL","KHNL","KHOU","KIAD","KIAH","KJFK","KLAS","KLAX","KLGA","KMCO","KMDW","KMEM","KMIA","KMSP","KORD",
    "KPDX","KPHL","KPHX","KSAN","KSEA","KSFO","KSLC","KSTL","KTPA"]

// export const traconReportFacilities = ["A80","A90","C90","CLT","CLT+ASDEX","CVG","D01","D10","D21","F11","I90",
//     "L30","M03","M98","MIA","MKE","N90","NCT","P50","P80","PCT","PHL","PHL+ASDEX","S46","S56","SBN","SCT","T75"]

export const traconReportFacilities = ["A11","A80","A90","ABQ","BUF","C90","CLT","CMH","CVG","D01","D10",
    "D21","F11","I90","IND","JAX","L30","M03","M98","MCI","MIA","MKE","N90","NCT","P50","P80","PCT","PHL",
    "PIT","PVD","R90","RDU","S46","S56","SBN","SCT","SDF","T75","TPA","Y90"]

export const userTypes = ["All", "External", "Internal"]